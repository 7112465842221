<template>
  <div class="project-container" v-if="loadedProjectData">
    <div class="grid">
      <div class="centered-flex">
        <h2 class="title">{{ loadedProjectData.title }}</h2>
        <hr class="separation" />
        <div class="project-basic-info">
          <div class="basic-group">
            <p class="basic-label medium">
              {{ loadedProjectData.companyTitle }}
            </p>
            <p class="basic-value p2">{{ loadedProjectData.company }}</p>
          </div>
          <div class="basic-group">
            <p class="basic-label medium">{{ loadedProjectData.yearTitle }}</p>
            <p class="p2">{{ loadedProjectData.year }}</p>
          </div>
          <div class="basic-group">
            <p class="basic-label medium">{{ loadedProjectData.typeTitle }}</p>
            <p class="p2">{{ loadedProjectData.type }}</p>
          </div>
        </div>
        <hr class="separation" />
        <p class="description-1">{{ loadedProjectData.description1 }}</p>
      </div>
    </div>
    <img
      v-if="loadedProjectData.bannerImage1"
      class="img-banner"
      :src="loadedProjectData.bannerImage1.sourceURL"
      :alt="loadedProjectData.bannerImage1.altText"
      :srcset="loadedProjectData.bannerImage1.srcSet"
      sizes="(max-width: 750px) 100vw, (max-width: 1100px) 75vw, 50vw"
    />
    <div class="grid">
      <div class="centered-flex">
        <div
          v-for="section in loadedProjectData.sections"
          :key="section.subtitle"
          class="section"
        >
          <h2 v-if="section?.subtitle" class="section-subtitle italic">
            {{ section.subtitle }}
          </h2>
          <p v-if="section?.description1" class="section-description-1">
            {{ section.description1 }}
          </p>
          <img
            v-if="section?.image1"
            class="section-img-1"
            :src="section.image1?.sourceURL"
            :alt="section.image1?.altText"
            :srcset="section.image1?.srcSet"
            sizes="(max-width: 750px) 78vw, (max-width: 1100px) 58vw, 40vw"
          />
          <div class="text-img-block">
            <p v-if="section?.description2"
               class="section-description-2"
               :class="{isalone : !section.image2}"
            >
              {{ section.description2 }}
            </p>
            <img
              class="section-img-2"
              :class="{isalone : !section.description2}"
              v-if="section?.image2"
              :src="section.image2?.sourceURL"
              :alt="section.image2?.altText"
              :srcset="section.image2?.srcSet"
              sizes="(max-width: 750px) 39vw, (max-width: 1100px) 29vw, 20vw"
            />
          </div>
        </div>
        <h2 class="subtitle-2 italic" v-if="loadedProjectData.subtitle">{{ loadedProjectData?.subtitle }}</h2>
        <a
          :href="loadedProjectData?.discoverUrl"
          target="_blank"
          class="discover-btn"
        >
          <RacButton :text="loadedProjectData?.discoverBtn" />
        </a>
      </div>
    </div>
    <img
      v-if="loadedProjectData.bannerImage2"
      class="img-banner"
      :src="loadedProjectData.bannerImage2?.sourceURL"
      :alt="loadedProjectData.bannerImage2?.altText"
      :srcset="loadedProjectData.bannerImage2?.srcSet"
      sizes="(max-width: 750px) 100vw, (max-width: 1100px) 75vw, 50vw"
    />
    <div class="ctas">
      <p>{{ loadedProjectData?.callToAction1 }}</p>
      <img
        src="../../assets/icons/down_orange_arrow.svg"
        alt="down orange arrow"
      />
    </div>
  </div>
</template>

<script>
import RacButton from "../Atoms/RacButton";

import { PROJECTS_QUERY } from "@/gql-operations/allGqlOperations";

export default {
  name: "projectPage",
  components: { RacButton },
  emits: ["closeRightPanel"],
  apollo: {
    getData: {
      query: PROJECTS_QUERY,
      update(data) {
        const projects = data.projects.nodes.map((node) => {
          return {
            projectTitle: node.title,
            ...node.projectsFields.content,
          };
        });
        this.$store.commit("setProjects", projects);
      },
    },
  },
  created() {
    this.updateCurrentProjectData();
  },
  data() {
    return {
      loadedProjectData: null,
    };
  },
  methods: {
    updateCurrentProjectData() {
      try {
        this.loadedProjectData = this.$store.getters.getProject(
          this.currentProjectName
        );
      } catch (e) {
        this.loadedProjectData = null;
      }
    },
  },
  computed: {
    currentProjectName() {
      return this.$route.params.project?.replaceAll("_", " ");
    },
  },
  watch: {
    currentProjectName() {
      //waiting for right panel to close
      setTimeout(() => {
        this.updateCurrentProjectData();
      }, 1000);
    },
    "$store.state.projects"() {
      this.updateCurrentProjectData();
    },
  },
};
</script>

<style scoped lang="scss">
.project-container {
  .grid {
    @include right-panel-grid;

    .centered-flex {
      display: flex;
      flex-direction: column;
      grid-column: 2 / span 10;

      .title {
        margin-bottom: 5vh;
      }

      .project-basic-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .basic-group {
          display: flex;
          flex-direction: column;
          flex-basis: 33%;
          text-align: center;

          .basic-label {
            color: $orange;
            margin-bottom: 2rem;
          }
        }
      }

      .separation {
        width: 100%;
        margin: 2rem 0 2rem 0;
      }

      .description-1 {
        margin-top: 5vh;
        white-space: pre-wrap;
      }

      .section {
        .section-subtitle {
          margin-bottom: 5vh;
        }
        .section-description-1 {
          margin-bottom: 5vh;
          white-space: pre-wrap;
        }
        .section-img-1 {
          width: 100%;
          margin-bottom: 8vh;
        }
        .text-img-block {
          display: flex;
          gap: 3rem;
          margin-bottom: 8vh;
          * {
            max-width: 50%;
          }
          .section-description-2 {
            white-space: pre-wrap;
          }
          .section-img-2 {
            width: 45%;
            height: auto;
            object-fit: contain;
          }
          .isalone {
            flex-basis: 100%;
            max-width: 100%;
          }
        }
      }

      .subtitle-2 {
        margin-bottom: 5vh;
      }
    }
  }

  .img-banner {
    grid-column: 1 / span 9;
    max-width: 100%;
    width: 100%;
    margin-bottom: 5vh;
  }

  .ctas {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4vh;
    * {
      max-width: 50%;
      text-align: center;
      color: $orange;
    }
  }
}
@media screen and (max-width: $xs-screen) {
  .project-container {
    .grid {
      .centered-flex {
        grid-column: 1 / span 12;
      }
    }
  }
}
</style>
